const K_SIZE = 64;

const pad2 = value => {
    const result = String(value);
    return result.length === 1 ? `0${ result }` : result;
};

const randomDigit = () => {
    const num = String(Math.random() * 1000);
    return num.charAt(0);
};

const generateNoise = size => {
    const result = new Array(size)
    result.fill(0);
    return result.map( _ => randomDigit() );
};

const dataPositions = [19, 31, 23, 17, 47, 53, 7, 29, 61, 41]; // , 5, 11, 3, 13, 2, 37, 43, 59

export const generateK = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = pad2(now.getMonth());
    const day = pad2(now.getDate());
    const hours = pad2(now.getHours());
    const k = generateNoise(K_SIZE);
    const data = `${ year }${ month }${ day }${ hours }`.split('');
    data.forEach( (digit, index) => {
        const pos = dataPositions[index];
        k[pos] = digit;
    });

    return k.join('');
};

export const validateKs = (k1, k2) => {
    if (k1.length !== K_SIZE || k2.length !== K_SIZE) {
        return false;
    }

    return !dataPositions.some(pos => k1[pos] !== k2[pos]);
};
