import { useState, useEffect, useRef } from "react";

function HeaderForm({ onSearchClick, keyWords }) {
    const [userValue, setUserValue] = useState("");
    const [suggestionPart, setSuggestionPart] = useState("");

    const inputRef = useRef();
    const userValueRef = useRef();
    const keyWordToSearch = userValue + suggestionPart;
    userValueRef.current = userValue;

    function handlerUserInputChange(e) {
        const inputValue = e.target.value;
        const newUserValue = inputValue;

        const diff = newUserValue.substr(userValue.length);
        if (suggestionPart.indexOf(diff) === 0) {
            setSuggestionPart(suggestionPart.substr(diff.length));
        } else {
            setSuggestionPart("");
        }

        setUserValue(newUserValue);
    }

    function handleKeykDown(e) {
        if (e.keyCode === 8 || e.keyCode === 46) {
            setSuggestionPart("");
        }
    }

    useEffect(() => {
        if (userValue.length > 0) {
            const findSuggestionFor = phrase => keyWords.find((word) => word.indexOf(phrase) === 0);        
            const suggestWord = findSuggestionFor(userValue);

            if (suggestWord) {
                const stillFits = suggestWord.indexOf(userValueRef.current) === 0;
                if (stillFits) {
                    setSuggestionPart(suggestWord.substr(userValueRef.current.length));
                } else {
                    setSuggestionPart("");
                }
            }
        } else {
            setSuggestionPart("")
        }
    }, [keyWords, userValue]);

    useEffect(() => {
        inputRef.current.selectionStart = userValueRef.current.length;
        inputRef.current.selectionEnd =
            userValueRef.current.length + suggestionPart.length;
    }, [suggestionPart]);

    return (
        <form className="d-flex">
            <input
                className="form-control me-2"
                type="search"
                placeholder="Search by keyword"
                aria-label="Search"
                id="input"
                ref={inputRef}
                onChange={ e => handlerUserInputChange(e) }
                value={userValue + suggestionPart}
                onKeyDown={ e => handleKeykDown(e) }
            />
            <button
                className="btn btn-danger"
                type="submit"
                onClick={(e) => onSearchClick(e, keyWordToSearch)}
            >
                Search
            </button>
        </form>
    );
}

export default HeaderForm;
