function Carousel({ items }) {
    return (
        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
                { items.map(
                    (_, index) => <button 
                            key={ `carousel-item-indicator-key-${ index }` } 
                            type="button" 
                            className={ index === 0 ? 'active' : '' }
                            data-bs-target="#myCarousel" 
                            data-bs-slide-to={ index }
                            aria-current={ index === 0 ? 'true': '' }
                            aria-label={ `Slide ${ index }`}
                    ></button>
                )}
            </div>
            <div className="carousel-inner">
                { items.map(
                    (item, index) =>
                        <div
                            key={ `carousel-item-content-key-${ index }` } 
                            className={`carousel-item${ index === 0 ? ' active' : ''}`} 
                            dangerouslySetInnerHTML={{__html: item.content }}
                        ></div>
                )}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
}

export default Carousel;
