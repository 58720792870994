function Footer({
    texto,
    textoContacto,
    email,
    emailBody,
    urlCondiciones,
    textoCondiciones,
    urlPrivacidad,
    textoPrivacidad,
}) {
    return (
        <footer className="py-5">
            <p className="text-center mb-0">{texto}</p>
            <ul className="footer-links text-center p-0">
                <li>
                    <a
                        href={`mailto:${email}?body=${emailBody}`}
                        className="d-block px-2"
                        lang="es"
                    >
                        { textoContacto }
                    </a>
                </li>
                <li>
                    <a
                        href={urlCondiciones}
                        className="d-block px-2"
                        lang="es"
                        target="_blank"
                        rel="noreferrer"
                    >
                        { textoCondiciones }
                    </a>
                </li>
                <li>
                    <a
                        href={urlPrivacidad}
                        className="d-block px-2"
                        lang="es"
                        target="_blank"
                        rel="noreferrer"
                    >
                        { textoPrivacidad }
                    </a>
                </li>
            </ul>
        </footer>
    );
}

export default Footer;
