import { useState } from "react";

import ButtonsCategoriesContainer from "./ButtonsCategoriesContainer";
import AlbumContainer from "./AlbumContainer";
import Carousel from "./Carousel";
import VideoModal from "./VideoModal";

function Main({
    carrusel,
    videoCategories,
    videosAlbum,
    category,
    routes,

    onVideoCategoryClick
}) {
    const [videoModal, setVideoModal] = useState();

    const showModal = myVideo => {
        setVideoModal(myVideo);
    };

    window.showVideo = e => {
        showModal({
            alt: e.dataset.title,
            id: e.dataset.id,
            title: e.dataset.title,
        });
    }
    
    return (
        <main>
            <Carousel items={ carrusel } />
            <ButtonsCategoriesContainer
                categories={ videoCategories || [] }
                onClick={ onVideoCategoryClick }
                selectedCategory={ category }
            />
            <AlbumContainer
                listVideos={ videosAlbum || [] }
                category={ category }
                onClickItem={ showModal }
                routes={ routes }
            />
            {/* Init modal */}
            <VideoModal 
                video={ videoModal } 
                category={ category } 
                routes={ routes } 
            />
            {/* End modal */}
        </main>
    );
}

export default Main;