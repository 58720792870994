import { useState, useEffect } from "react";

import MainPage from "./components/containers/main-page";
import VideoPage from "./components/containers/video-page";

import { DataManager } from "./data-manager";
import { Selectors } from "./Miners";

function App() {
    const [data, setData] = useState();
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedGrade, setSelectedGrade] = useState("");
    const [keyWord, setKeyWord] = useState("");
    const [idVideo, setIdVideo] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const initialData = await DataManager.loadData();
            setData(initialData);
        };
        fetchData();
    }, []);

    useEffect(() => {
        const values = window.location.search;
        const urlParams = new URLSearchParams(values);
        const category = urlParams.get("category") || ''; //  || "All videos";
        const grade = urlParams.get("grade") || ''; // A1
        const idVideoUrl = urlParams.get("id");

        setSelectedCategory(category);
        setSelectedGrade(grade);

        if (idVideoUrl !== null) {
            setIdVideo(idVideoUrl);
        }
    }, [data]);

    if (data === undefined) {
        return <h3>Loading</h3>;
    }

    const videoById = Selectors.videoById(data, idVideo);

    const onDropGradeClick = grade => {
        setSelectedGrade(grade);
    };

    const onVideoCategoryClick = cat => {
        setSelectedCategory(cat);
    };

    const onSearchClick = (e, keyWord) => {
        e.preventDefault();
        setKeyWord(keyWord);
    };

    const videos = Selectors.videosByFilters(
        data,
        selectedCategory,
        selectedGrade,
        keyWord
    );

    const mainPage = (
        <MainPage
            logo={ Selectors.getChanelLogo(data) }
            about={ Selectors.getAboutButton(data) }
            drops={ Selectors.getHeaderGrades(data) }
            keyWords={ Selectors.getKeyWords(data) }
            flippedButton={ Selectors.getFlippedButton(data) }

            onDropGradeClick={ onDropGradeClick }
            onSearchClick={ onSearchClick }

            carrusel={ Selectors.getCarrusel(data) }
            videoCategories={ Selectors.getVideoCategories(data) }
            videosAlbum={ videos || [] }
            onVideoCategoryClick={ onVideoCategoryClick }
            category={ selectedCategory }
            routes={ Selectors.getRoutesConfig(data) }
            footer={ Selectors.getDataFooter(data) }
        />
    );

    const videoPage = (
        <VideoPage video={videoById} routes={Selectors.getRoutesConfig(data)} />
    );

    function showPage() {
        if (idVideo) {
            return videoPage;
        } else {
            return mainPage;
        }
    }

    return <>{showPage()}</>;
}

export default App;
