import AlbumItem from './AlbumItem';

function AlbumContainer({ listVideos, category, onClickItem, routes }) {
    listVideos.sort(
        (a,b) => 
        (a.title.replace(/<[^>]+>/g, '').toUpperCase() > b.title.replace(/<[^>]+>/g, '').toUpperCase()) ? 1 : 
        ((b.title.replace(/<[^>]+>/g, '').toUpperCase() > a.title.replace(/<[^>]+>/g, '').toUpperCase()) ? -1 
        : 0))
    // window.console.log('listVideos',listVideos)
    const videosAlbum = listVideos.map(item => (
        <AlbumItem
            video={ item }
            key={ item.id }
            category={ category }
            onClick={ onClickItem }
            routes={ routes }
        />
    ));

    return (
        <div className="album py-5 bg-light">
            <div className="container">
                <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-xl-4 g-3">
                    { videosAlbum }
                </div>
            </div>
        </div>
    );
}

export default AlbumContainer;
