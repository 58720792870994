const videosByFilters = (data, selectedCategory, selectedGrade, keyWord) => {
    const videosTotal = getVideos(data);
    const result = videosTotal.filter((video) => {
        const cumpleCategoria = video.categories.includes(selectedCategory) || selectedCategory === "" || selectedCategory === "All videos";
        const cumpleGrado = video.cefrGrade === selectedGrade || video.cambridgeGrade === selectedGrade || selectedGrade === "";
        const cumpleKeyWord = video.keyWords.includes(keyWord) || keyWord === "";

        return cumpleCategoria && cumpleGrado && cumpleKeyWord;
    });
    return result;
}

const videoById = (data, id) => {
    const videosTotal = getVideos(data);
    const result = videosTotal.find((video) =>
        video.id === id
    );
    return result
}

const getDataFooter = data => data.footer;
const getVideoCategories = data => data.videoCategories;
const getVideos = data => data.videos;
const getChanelLogo = data => data.chanelLogo;
const getFlippedButton = data => data.flippedButton;
const getAboutButton = data => data.aboutButton;
const getHeaderGrades = data => data.headerGrades;
const getKeyWords = data => data.keyWords;
const getRoutesConfig = data => data.routesConfig;
const getCarrusel = data => data.carrusel;

export const Selectors = {
    getAboutButton,
    getChanelLogo,
    getDataFooter,
    getFlippedButton,
    getHeaderGrades,
    getVideos,
    getVideoCategories,
    getKeyWords,
    getCarrusel,
    videosByFilters,
    videoById,
    getRoutesConfig,
};

