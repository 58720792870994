import { useEffect, useRef } from "react";

function VideoInfo({ cerfGrade, cambridgeGrade, category }) {
    return (
        <div className="container">
            <div className="pt-1 border border-2 border-danger rounded d-flex flex-row justify-content-around">
                <div className="mx-2 mt-1">
                    <h6>
                        CEFR: <span className="revealed-color">{ cerfGrade }</span>
                    </h6>
                </div>
                <div className="mx-2 mt-1">
                    <h6>
                        Cambridge:{" "}
                        <span className="revealed-color">{ cambridgeGrade }</span>
                    </h6>
                </div>

                <div className="mx-2 mt-1">
                    <h6>
                        Category:{" "}
                        <span className="revealed-color">{ category }</span>
                    </h6>
                </div>
            </div>
        </div>
    );
}

function VideoModal({ video, category, routes }) {
    const categoriaVideo = video && video.categories ? video.categories : ''
    const urlBaseVideo = routes.videos;
    const videoModalLabel = video && video.videoModalLabel ? video.videoModalLabel.replace(/<[^>]+>/g, '') : "";
    const title = video ? video.title : "";
    const cerfGrade = video ? video.cefrGrade : "";
    const cambridgeGrade = video ? video.cambridgeGrade : "";
    const urlVideo = video ? `${urlBaseVideo + video.id + ".mp4"}` : "";

    const videoRef = useRef();

    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            video.load();
        }
    }, [video]);

    const cerrarVideo = () => {
        const video = videoRef.current;
        if (video) {
            video.pause();
            video.currentTime = 0;
        }
    };

    return (
        <div className="modals">
            <div
                className="modal fade"
                id="video-modal"
                tabIndex="-1"
                aria-labelledby={videoModalLabel}
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id={videoModalLabel}
                            dangerouslySetInnerHTML={{__html: title }}>
                                {/*title*/}
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={cerrarVideo}
                            ></button>
                        </div>
                        <div className="modal-body">
                            { (categoriaVideo || cerfGrade || cambridgeGrade) &&
                                <VideoInfo 
                                    cerfGrade={ cerfGrade } 
                                    cambridgeGrade={ cambridgeGrade } 
                                    category={ categoriaVideo }
                                ></VideoInfo>
                            }
                            <div className="container py-3">
                                <video
                                    width="100%"
                                    height="auto"
                                    controls
                                    preload="auto"
                                    ref={videoRef}
                                >
                                    <source src={urlVideo} type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VideoModal;
