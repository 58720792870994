import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { generateK, validateKs } from "./security";

const securityCheck = () => {
    const params = window.location.search;
    const urlParams = new URLSearchParams(params);
    const k = urlParams.get("k") || "not-allowed";

    const expectedK = generateK();

    if (!validateKs(k, expectedK)) {
        window.location = "not-allowed.html";
    }
};
securityCheck();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// reportWebVitals(console.log);
