/* eslint-disable jsx-a11y/anchor-is-valid */
function AlbumItem({ video, category, onClick, routes }) {
  const urlThumbnail = video ? `${routes.thumbnails + video.id + ".png"}` : "";
  return (
    <>
      <div className="col album-item">
        <div className="card shadow-sm">
          <div className="col">
            <div className="card shadow-sm">
              <div className="card-container">
                <img
                  className="bd-placeholder-img card-img-top"
                  src={urlThumbnail}
                  alt={video.alt.replace(/<[^>]+>/g, '')}
                  width="100%"
                  height="auto"
                  //onClick={goTovideoPage}
                />
                <div className="leveltags">
                  <div>
                    <p className="leveltag-cefr">{video.cefrGrade}</p>
                  </div>
                  <div>
                    <p className="leveltag-cambridge">{video.cambridgeGrade}</p>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <p className="card-text">
                  <span
                    href=""
                    //onClick={goTovideoPage}
                    onClick={() => onClick(video, category)}
                    className="stretched-link"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                    title={video.title.replace(/<[^>]+>/g, '')}
                    dangerouslySetInnerHTML={{__html: video.title }}
                  >
                    {/*video.title*/}
                  </span>
                </p>
                <div className="d-flex justify-content-between align-items-center"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AlbumItem;
