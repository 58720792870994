function ButtonCategory({ category, onClick, selected }) {
  const classes = selected
    ? "btn rounded-pill btn-danger mb-2 me-1"
    : "btn rounded-pill btn-outline-danger mb-2 me-1";
  //Añadido me-1 para conseguir seperacion entre botones
  return (
    <button
      type="button"
      className={classes}
      onClick={() => onClick(category)}
    >
      {category}
    </button>
  );
}

export default ButtonCategory;
