import Header from "../header/Header";
import Main from "../main/Main";
import Footer from "../footer/Footer";

function MainPage({
    logo,
    about,
    drops,
    keyWords,
    flippedButton,

    carrusel,
    videoCategories,
    videosAlbum,
    onVideoCategoryClick,
    category,
    routes,
    footer,

    onDropGradeClick,
    onSearchClick
}) {
    return (
        <>
            <Header
                logo={ logo }
                about={ about }
                drops={ drops }
                keyWords={ keyWords }
                flippedButton={ flippedButton }
                onDropGradeClick={ onDropGradeClick }
                onSearchClick={ onSearchClick }
            />
            <Main
                carrusel={ carrusel }
                videoCategories={ videoCategories }
                videosAlbum={ videosAlbum }
                onVideoCategoryClick={ onVideoCategoryClick }
                category={ category }
                routes={ routes }
            />
            <Footer
                texto={footer.texto}
                textoContacto={footer.textoContacto}
                email={footer.email}
                emailBody={footer.emailBody}
                urlCondiciones={footer.urlCondiciones}
                textoCondiciones={footer.textoCondiciones}
                urlPrivacidad={footer.urlPrivacidad}
                textoPrivacidad={footer.textoPrivacidad}
            />
        </>
    );
}

export default MainPage;
