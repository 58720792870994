function HeaderButton({ text, onClick }) {
    return (
        <button 
            type="button" 
            className="btn btn-outline-danger btn-lg px-3 py-1"
            onClick={ onClick }
        >
            { text }
        </button>
    );
}

export default HeaderButton;
