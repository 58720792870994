function VideoPage({ video, routes }) {
  
  const urlBaseVideo = routes.videos;
  const title = video ? video.title : "";
  const cerfGrade = video ? video.cefrGrade : "";
  const cambridgeGrade = video ? video.cambridgeGrade : "";
  const urlVideo = video ? `${urlBaseVideo + video.id + ".mp4"}` : "";
  const categories = video ? video.categories : []

  const vCategories = categories.toString();

  return (
    <>
      <header>
        <nav className="navbar navbar-expand-xl navbar-light bg-white">
          <div className="container">
            <a className="navbar-brand" href="#">
              <img
                src="assets/img/grammar-channel-logo.png"
                alt="Richmond Grammar Channel"
              />
            </a>
          </div>
        </nav>
      </header>

      <main>
        <div className="container">
          <div id="uno"></div>
          <div className="border border-2 border-danger rounded d-flex flex-row justify-content-around">
            <div className="mx-2 mt-1">
              <h5>
                CEFR:{" "}
                <span className="revealed-color" id="cefrGrade">
                  {cerfGrade}
                </span>
              </h5>
            </div>
            <div className="mx-2 mt-1">
              <h5>
                Cambridge:{" "}
                <span className="revealed-color" id="cambridgeGrade">
                  {cambridgeGrade}
                </span>
              </h5>
            </div>

            <div className="mx-2 mt-1">
              <h5>
                Category:{" "}
                <span className="revealed-color" id="videoCategory">
                  {vCategories}
                </span>
              </h5>
            </div>
          </div>
        </div>
        <div className="container py-3">
          <h3 className="titulo text-center mt-1 mb-4" id="videoTitulo">
            <span /*className="fst-italic"*/ dangerouslySetInnerHTML={{__html: title }}>
              {/*title*/}
            </span>
          </h3>
          <video width="100%" height="auto" controls preload="auto" id="video">
            <source id="videoUrl" src={urlVideo} type="video/mp4" />
          </video>
        </div>
      </main>

      <footer className="footer mt-auto py-2">
        <p className="text-center mb-0">© Sanoma Educación S.L.U / Richmond</p>
      </footer>
    </>
  );
}

export default VideoPage;
