import ButtonCategory from "./ButtonCategory";

function ButtonsCategoriesContainer({ categories, onClick, selectedCategory }) {
    const buttons = categories.map(item => (
        <ButtonCategory
            category={ item.category }
            onClick={ onClick }
            key={ item.category }
            selected={ item.category === selectedCategory }
        />
    ));

    return (
        <div className="tagcloud mb-5">
            <div className="container text-center">
                <h2 className="h4 mb-4">Filter by category:</h2>
                { buttons }
            </div>
        </div>
    );
}

export default ButtonsCategoriesContainer;
